<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="success" inline title="Cititor" class="px-5 py-3">
          <v-form>
            <v-container class="py-0" fluid>
              <v-row dense class="text-no-wrap" align="center">
                <v-col cols="12" sm="8" class="order-1 order-sm-0">
                  <v-row :dense="dense" class="text-no-wrap" align="center">
                    <v-col cols="12">
                      <v-textarea
                        v-model="record.fields['200'][0].a[0].val"
                        :rules="validateValue('200', 'a')"
                        rows="2"
                        :hide-details="hided"
                        autofocus
                        :dense="dense"
                        :label="'200a ' + $t('Titlu')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['010'][0].a[0].val"
                        :rules="validateDuplicate(false, dictionary['010a'])"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'010a ' + $t('ISBN')"
                        @blur="checkDuplicate('mon', '010', 'a', record.fields['010'][0].a[0].val)"
                      />
                    </v-col>
                    <v-col v-for="(fld, ind) in record.fields['035']" :key="'035' + ind" cols="12" sm="6">
                      <v-hover v-slot="{ hover }">
                        <s-autocomplete
                          v-model="fld.a[0].val"
                          :rules="validateValue('035', 'a')"
                          :label="'035a ' + $t('Cota')"
                          :dense="dense"
                          :hide-details="hided"
                          :clearable="false"
                          view-type="marc"
                          :item-text="'fields.035.a.val'"
                          :item-value="'fields.035.a.val'"
                          collection="ebib_marc_mon"
                          @focus="$set(dictionary, '035avshow' + ind, true)"
                          @blur="$set(dictionary, '035avshow' + ind, false)"
                        >
                          <template #append-outer>
                            <div
                              v-show="dictionary['035avshow' + ind] || hover"
                              :class="{ 'd-inline-flex': dictionary['035avshow' + ind] || hover }"
                            >
                              <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.035', ind + 1)">
                                mdi-plus
                              </v-icon>
                              <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.035', ind)">
                                mdi-minus
                              </v-icon>
                            </div>
                          </template>
                        </s-autocomplete>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center order-0 order-sm-1">
                  <span class="d-none">{{ render }}</span>
                  <add-image-multiple
                    :value="record.fields['856']"
                    :height="150"
                    :width="150"
                    @input="
                      $set(record.fields, '856', $event)
                      render++
                    "
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col v-for="(fld, ind) in record.fields['210'][0].c" :key="'210' + ind" cols="12" sm="4">
                  <v-hover v-slot="{ hover }">
                    <s-autocomplete
                      v-model="fld.val"
                      :rules="validateValue('210', 'c')"
                      :label="'210c ' + $t('Editura')"
                      :dense="dense"
                      :hide-details="hided"
                      :clearable="false"
                      view-type="marc"
                      :item-text="'fields.210.c.val'"
                      :item-value="'fields.210.c.val'"
                      collection="ebib_marc_mon"
                      @focus="$set(dictionary, '210cvshow' + ind, true)"
                      @blur="$set(dictionary, '210cvshow' + ind, false)"
                    >
                      <template #append-outer>
                        <div
                          v-show="dictionary['210cvshow' + ind] || hover"
                          :class="{ 'd-inline-flex': dictionary['210cvshow' + ind] || hover }"
                        >
                          <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.210.0.c', ind + 1)">
                            mdi-plus
                          </v-icon>
                          <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.210.0.c', ind)">
                            mdi-minus
                          </v-icon>
                        </div>
                      </template>
                    </s-autocomplete>
                  </v-hover>
                </v-col>
                <v-col cols="12" sm="4">
                  <s-autocomplete
                    v-model="record.fields['210'][0].a[0].val"
                    :rules="validateValue('210', 'a')"
                    :label="'210a ' + $t('Localitate')"
                    :dense="dense"
                    :hide-details="hided"
                    :clearable="false"
                    view-type="marc"
                    :item-text="'fields.210.a.val'"
                    :item-value="'fields.210.a.val'"
                    collection="ebib_marc_mon"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="record.fields['210'][0].d[0].val"
                    :rules="validateValue('210', 'd')"
                    :hide-details="hided"
                    :dense="dense"
                    :label="'210d ' + $t('Data publicării')"
                  />
                </v-col>
              </v-row>
              <base-material-tabs
                :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                color="primary"
                show-arrows
                center-active
                centered
                fixed-tabs
              >
                <v-tab>{{ $t("Resp./autor") }}</v-tab>
                <v-tab>{{ $t("Descriere") }}</v-tab>
                <v-tab>{{ $t("Clasificări") }}</v-tab>
                <v-tab>{{ $t("Note") }}</v-tab>
                <v-tab :disabled="!record._id">{{ $t("Legături") }}</v-tab>
                <v-tab>{{ $t("Resurse") }}</v-tab>
                <v-tab>{{ $t("Identificare") }}</v-tab>
                <v-tab :disabled="!record._id">{{ $t("Istoric") }}</v-tab>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-hover v-slot="{ hover }">
                      <v-col cols="12" sm="6">
                        <span class="d-none">{{ render }}</span>
                        <s-autocomplete
                          :value="record.fields['700'][0].a[0].val"
                          where='"ctg":"nume-personal",'
                          category="nume-personal"
                          record-field="200"
                          :label="'700a ' + $t('Autor')"
                          :dense="dense"
                          :hide-details="hided"
                          :clearable="false"
                          return-object
                          show-add
                          view-type="marcath"
                          item-text="fields.299.a.val"
                          item-value="fields.299.a.val"
                          collection="ebib_marc_ath"
                          @search="searchRelationMarc('700', 'a', 0, 'ath', 'nume-personal')"
                          @input="setLink('700', 'a', 0, 'ath', 'nume-personal', $event)"
                          @focus="$set(dictionary, '700avshow', true)"
                          @blur="$set(dictionary, '700avshow', false)"
                        >
                          <template #append>
                            <v-icon
                              v-show="dictionary['700avshow'] || hover"
                              class="pt-1"
                              tabindex="-1"
                              @click.stop.prevent="searchRelationMarc('700', 'a', 0, 'ath', 'nume-personal')"
                            >
                              mdi-magnify
                            </v-icon>
                          </template>
                        </s-autocomplete>
                      </v-col>
                    </v-hover>
                    <v-col cols="12" sm="6">
                      <v-row v-for="(fld, ind) in record.fields['701']" :key="'701' + ind" dense>
                        <v-col cols="12">
                          <span class="d-none">{{ render }}</span>
                          <v-hover v-slot="{ hover }">
                            <s-autocomplete
                              :value="fld.a[0].val"
                              where='"ctg":"nume-personal",'
                              category="nume-personal"
                              record-field="200"
                              :label="'701a ' + $t('Autor alternativ')"
                              :dense="dense"
                              :hide-details="hided"
                              :clearable="false"
                              return-object
                              show-add
                              view-type="marcath"
                              item-text="fields.299.a.val"
                              item-value="fields.299.a.val"
                              collection="ebib_marc_ath"
                              @search="searchRelationMarc('701', 'a', ind, 'ath', 'nume-personal')"
                              @input="setLink('701', 'a', ind, 'ath', 'nume-personal', $event)"
                              @focus="$set(dictionary, '701avshow' + ind, true)"
                              @blur="$set(dictionary, '701avshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['701avshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['701avshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.701', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.701', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                              <template #append>
                                <v-icon
                                  v-show="dictionary['701avshow' + ind] || hover"
                                  class="pt-1"
                                  tabindex="-1"
                                  @click.stop.prevent="searchRelationMarc('701', 'a', ind, 'ath', 'nume-personal')"
                                >
                                  mdi-magnify
                                </v-icon>
                              </template>
                            </s-autocomplete>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-for="(fld, ind) in record.fields['702']" :key="'702' + ind" dense>
                    <v-col cols="12" sm="6">
                      <span class="d-none">{{ render }}</span>
                      <v-hover v-slot="{ hover }">
                        <s-autocomplete
                          :value="fld.a[0].val"
                          where='"ctg":"nume-personal",'
                          category="nume-personal"
                          record-field="200"
                          :label="'702a ' + $t('Autor secundar')"
                          :dense="dense"
                          :hide-details="hided"
                          :clearable="false"
                          return-object
                          show-add
                          view-type="marcath"
                          item-text="fields.299.a.val"
                          item-value="fields.299.a.val"
                          collection="ebib_marc_ath"
                          @search="searchRelationMarc('702', 'a', ind, 'ath', 'nume-personal')"
                          @input="setLink('702', 'a', ind, 'ath', 'nume-personal', $event)"
                          @focus="$set(dictionary, '702avshow' + ind, true)"
                          @blur="$set(dictionary, '702avshow' + ind, false)"
                        >
                          <template #append-outer>
                            <div
                              v-show="dictionary['702avshow' + ind] || hover"
                              :class="{ 'd-inline-flex': dictionary['702avshow' + ind] || hover }"
                            >
                              <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.702', ind + 1)">
                                mdi-plus
                              </v-icon>
                              <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.702', ind)">
                                mdi-minus
                              </v-icon>
                            </div>
                          </template>
                          <template #append>
                            <v-icon
                              v-show="dictionary['702avshow' + ind] || hover"
                              class="pt-1"
                              tabindex="-1"
                              @click.stop.prevent="searchRelationMarc('702', 'a', ind, 'ath', 'nume-personal')"
                            >
                              mdi-magnify
                            </v-icon>
                          </template>
                        </s-autocomplete>
                      </v-hover>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="fld['4'][0].val"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'7024 ' + $t('Cod de legatura')"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <v-row v-for="(fld, ind) in record.fields['710']" :key="'710' + ind" dense>
                        <v-hover v-slot="{ hover }">
                          <v-col cols="12">
                            <span class="d-none">{{ render }}</span>
                            <s-autocomplete
                              :value="fld.a[0].val"
                              where='"ctg":"nume-corporatie",'
                              category="nume-corporatie"
                              record-field="210"
                              :label="'710a ' + $t('Nume instituţie primara')"
                              :dense="dense"
                              :hide-details="hided"
                              :clearable="false"
                              return-object
                              show-add
                              view-type="marcath"
                              item-text="fields.299.a.val"
                              item-value="fields.299.a.val"
                              collection="ebib_marc_ath"
                              @search="searchRelationMarc('710', 'a', ind, 'ath', 'nume-corporatie')"
                              @input="setLink('710', 'a', ind, 'ath', 'nume-corporatie', $event)"
                              @focus="$set(dictionary, '710avshow' + ind, true)"
                              @blur="$set(dictionary, '710avshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['710avshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['710avshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.710', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.710', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                              <template #append>
                                <v-icon
                                  v-show="dictionary['710avshow' + ind] || hover"
                                  class="pt-1"
                                  tabindex="-1"
                                  @click.stop.prevent="searchRelationMarc('710', 'a', ind, 'ath', 'nume-corporatie')"
                                >
                                  mdi-magnify
                                </v-icon>
                              </template>
                            </s-autocomplete>
                          </v-col>
                        </v-hover>
                      </v-row>
                      <v-row v-for="(fld, ind) in record.fields['711']" :key="'711' + ind" dense>
                        <v-hover v-slot="{ hover }">
                          <v-col cols="12">
                            <span class="d-none">{{ render }}</span>
                            <s-autocomplete
                              :value="fld.a[0].val"
                              where='"ctg":"nume-corporatie",'
                              category="nume-corporatie"
                              record-field="210"
                              :label="'711a ' + $t('Nume institutie alternativa')"
                              :dense="dense"
                              :hide-details="hided"
                              :clearable="false"
                              return-object
                              show-add
                              view-type="marcath"
                              item-text="fields.299.a.val"
                              item-value="fields.299.a.val"
                              collection="ebib_marc_ath"
                              @search="searchRelationMarc('711', 'a', ind, 'ath', 'nume-corporatie')"
                              @input="setLink('711', 'a', ind, 'ath', 'nume-corporatie', $event)"
                              @focus="$set(dictionary, '711avshow' + ind, true)"
                              @blur="$set(dictionary, '711avshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['711avshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['711avshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.711', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.711', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                              <template #append>
                                <v-icon
                                  v-show="dictionary['711avshow' + ind] || hover"
                                  class="pt-1"
                                  tabindex="-1"
                                  @click.stop.prevent="searchRelationMarc('711', 'a', ind, 'ath', 'nume-corporatie')"
                                >
                                  mdi-magnify
                                </v-icon>
                              </template>
                            </s-autocomplete>
                          </v-col>
                        </v-hover>
                      </v-row>
                      <v-row v-for="(fld, ind) in record.fields['712']" :key="'712' + ind" dense>
                        <v-hover v-slot="{ hover }">
                          <v-col cols="12">
                            <span class="d-none">{{ render }}</span>
                            <s-autocomplete
                              :value="fld.a[0].val"
                              where='"ctg":"nume-corporatie",'
                              category="nume-corporatie"
                              record-field="210"
                              :label="'712a ' + $t('Nume de instituţie / resp. intelectuală secundară')"
                              :dense="dense"
                              :hide-details="hided"
                              :clearable="false"
                              return-object
                              show-add
                              view-type="marcath"
                              item-text="fields.299.a.val"
                              item-value="fields.299.a.val"
                              collection="ebib_marc_ath"
                              @search="searchRelationMarc('712', 'a', ind, 'ath', 'nume-corporatie')"
                              @input="setLink('712', 'a', ind, 'ath', 'nume-corporatie', $event)"
                              @focus="$set(dictionary, '712avshow' + ind, true)"
                              @blur="$set(dictionary, '712avshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['712avshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['712avshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.712', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.712', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                              <template #append>
                                <v-icon
                                  v-show="dictionary['712avshow' + ind] || hover"
                                  class="pt-1"
                                  tabindex="-1"
                                  @click.stop.prevent="searchRelationMarc('712', 'a', ind, 'ath', 'nume-corporatie')"
                                >
                                  mdi-magnify
                                </v-icon>
                              </template>
                            </s-autocomplete>
                          </v-col>
                        </v-hover>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-row v-for="(fld, ind) in record.fields['720']" :key="'720' + ind" dense>
                        <v-hover v-slot="{ hover }">
                          <v-col cols="12">
                            <span class="d-none">{{ render }}</span>
                            <s-autocomplete
                              :value="fld.a[0].val"
                              where='"ctg":"nume-de-familie",'
                              category="nume-de-familie"
                              record-field="220"
                              :label="'720a ' + $t('Nume de familie/ resp. intelectuală alternativă')"
                              :dense="dense"
                              :hide-details="hided"
                              :clearable="false"
                              return-object
                              show-add
                              view-type="marcath"
                              item-text="fields.299.a.val"
                              item-value="fields.299.a.val"
                              collection="ebib_marc_ath"
                              @search="searchRelationMarc('720', 'a', ind, 'ath', 'nume-de-familie')"
                              @input="setLink('720', 'a', ind, 'ath', 'nume-de-familie', $event)"
                              @focus="$set(dictionary, '720avshow' + ind, true)"
                              @blur="$set(dictionary, '720avshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['720avshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['720avshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.720', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.720', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                              <template #append>
                                <v-icon
                                  v-show="dictionary['720avshow' + ind] || hover"
                                  class="pt-1"
                                  tabindex="-1"
                                  @click.stop.prevent="searchRelationMarc('720', 'a', ind, 'ath', 'nume-de-familie')"
                                >
                                  mdi-magnify
                                </v-icon>
                              </template>
                            </s-autocomplete>
                          </v-col>
                        </v-hover>
                      </v-row>
                      <v-row v-for="(fld, ind) in record.fields['721']" :key="'721' + ind" dense>
                        <v-hover v-slot="{ hover }">
                          <v-col cols="12">
                            <span class="d-none">{{ render }}</span>
                            <s-autocomplete
                              :value="fld.a[0].val"
                              where='"ctg":"nume-de-familie",'
                              category="nume-de-familie"
                              record-field="220"
                              :label="'721a ' + $t('Nume de familie/ resp. intelectuală alternativă')"
                              :dense="dense"
                              :hide-details="hided"
                              :clearable="false"
                              return-object
                              show-add
                              view-type="marcath"
                              item-text="fields.299.a.val"
                              item-value="fields.299.a.val"
                              collection="ebib_marc_ath"
                              @search="searchRelationMarc('721', 'a', ind, 'ath', 'nume-de-familie')"
                              @input="setLink('721', 'a', ind, 'ath', 'nume-de-familie', $event)"
                              @focus="$set(dictionary, '721avshow' + ind, true)"
                              @blur="$set(dictionary, '721avshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['721avshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['721avshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.721', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.721', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                              <template #append>
                                <v-icon
                                  v-show="dictionary['721avshow' + ind] || hover"
                                  class="pt-1"
                                  tabindex="-1"
                                  @click.stop.prevent="searchRelationMarc('721', 'a', ind, 'ath', 'nume-de-familie')"
                                >
                                  mdi-magnify
                                </v-icon>
                              </template>
                            </s-autocomplete>
                          </v-col>
                        </v-hover>
                      </v-row>
                      <v-row v-for="(fld, ind) in record.fields['722']" :key="'722' + ind" dense>
                        <v-hover v-slot="{ hover }">
                          <v-col cols="12">
                            <span class="d-none">{{ render }}</span>
                            <s-autocomplete
                              :value="fld.a[0].val"
                              where='"ctg":"nume-de-familie",'
                              category="nume-de-familie"
                              record-field="220"
                              :label="'722a ' + $t('Nume de familie/ resp. intelectuală secundară')"
                              :dense="dense"
                              :hide-details="hided"
                              :clearable="false"
                              return-object
                              show-add
                              view-type="marcath"
                              item-text="fields.299.a.val"
                              item-value="fields.299.a.val"
                              collection="ebib_marc_ath"
                              @search="searchRelationMarc('722', 'a', ind, 'ath', 'nume-de-familie')"
                              @input="setLink('722', 'a', ind, 'ath', 'nume-de-familie', $event)"
                              @focus="$set(dictionary, '722avshow' + ind, true)"
                              @blur="$set(dictionary, '722avshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['722avshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['722avshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.722', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.722', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                              <template #append>
                                <v-icon
                                  v-show="dictionary['722avshow' + ind] || hover"
                                  class="pt-1"
                                  tabindex="-1"
                                  @click.stop.prevent="searchRelationMarc('722', 'a', ind, 'ath', 'nume-de-familie')"
                                >
                                  mdi-magnify
                                </v-icon>
                              </template>
                            </s-autocomplete>
                          </v-col>
                        </v-hover>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <v-row dense>
                        <v-col v-for="(sub, ind) in record.fields['200'][0].b" :key="'200b' + ind" cols="12">
                          <span class="d-none">{{ render }}</span>
                          <v-hover v-slot="{ hover }">
                            <v-autocomplete
                              v-model="sub.val"
                              :rules="validateValue('200', 'b')"
                              :hide-details="hided"
                              :dense="dense"
                              item-text="name"
                              item-value="value"
                              :label="'200b ' + $t('Desemnarea gen. a materialului')"
                              :items="dictionary['mediuPubl']"
                              @focus="$set(dictionary, '200bvshow' + ind, true)"
                              @blur="$set(dictionary, '200bvshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['200bvshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['200bvshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.200.0.b', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.200.0.b', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                            </v-autocomplete>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-row dense>
                        <v-col v-for="(sub, ind) in record.fields['200'][0].c" :key="'200c' + ind" cols="12">
                          <span class="d-none">{{ render }}</span>
                          <v-hover v-slot="{ hover }">
                            <v-text-field
                              v-model="sub.val"
                              :rules="validateValue('200', 'c')"
                              :hide-details="hided"
                              :dense="dense"
                              :label="'200c ' + $t('Titlu propus de alt autor')"
                              @focus="$set(dictionary, '200cvshow' + ind, true)"
                              @blur="$set(dictionary, '200cvshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['200cvshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['200cvshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.200.0.c', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.200.0.c', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                            </v-text-field>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-row dense>
                        <v-col v-for="(sub, ind) in record.fields['200'][0].d" :key="'200d' + ind" cols="12">
                          <span class="d-none">{{ render }}</span>
                          <v-hover v-slot="{ hover }">
                            <v-text-field
                              v-model="sub.val"
                              :rules="validateValue('200', 'd')"
                              :hide-details="hided"
                              :dense="dense"
                              :label="'200d ' + $t('Titlu paralel propriu-zis')"
                              @focus="$set(dictionary, '200dvshow' + ind, true)"
                              @blur="$set(dictionary, '200dvshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['200dvshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['200dvshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.200.0.d', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.200.0.d', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                            </v-text-field>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-row dense>
                        <v-col v-for="(sub, ind) in record.fields['200'][0].e" :key="'200e' + ind" cols="12">
                          <span class="d-none">{{ render }}</span>
                          <v-hover v-slot="{ hover }">
                            <v-text-field
                              v-model="sub.val"
                              :rules="validateValue('200', 'e')"
                              :hide-details="hided"
                              :dense="dense"
                              :label="'200e ' + $t('Alte informaţii la titlu')"
                              @focus="$set(dictionary, '200evshow' + ind, true)"
                              @blur="$set(dictionary, '200evshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['200evshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['200evshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.200.0.e', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.200.0.e', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                            </v-text-field>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-row dense>
                        <v-col v-for="(sub, ind) in record.fields['200'][0].f" :key="'200f' + ind" cols="12">
                          <span class="d-none">{{ render }}</span>
                          <v-hover v-slot="{ hover }">
                            <v-text-field
                              v-model="sub.val"
                              :rules="validateValue('200', 'f')"
                              :hide-details="hided"
                              :dense="dense"
                              :label="'200f ' + $t('Prima menţ. de resp.')"
                              @focus="$set(dictionary, '200fvshow' + ind, true)"
                              @blur="$set(dictionary, '200fvshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['200fvshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['200fvshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.200.0.f', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.200.0.f', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                            </v-text-field>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-row dense>
                        <v-col v-for="(sub, ind) in record.fields['200'][0].g" :key="'200g' + ind" cols="12">
                          <span class="d-none">{{ render }}</span>
                          <v-hover v-slot="{ hover }">
                            <v-text-field
                              v-model="sub.val"
                              :rules="validateValue('200', 'g')"
                              :hide-details="hided"
                              :dense="dense"
                              :label="'200g ' + $t('Urm. menţ. de responsabilitate')"
                              @focus="$set(dictionary, '200gvshow' + ind, true)"
                              @blur="$set(dictionary, '200gvshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['200gvshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['200gvshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.200.0.g', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.200.0.g', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                            </v-text-field>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-row dense>
                        <v-col v-for="(sub, ind) in record.fields['200'][0].h" :key="'200h' + ind" cols="12">
                          <span class="d-none">{{ render }}</span>
                          <v-hover v-slot="{ hover }">
                            <v-text-field
                              v-model="sub.val"
                              :rules="validateValue('200', 'h')"
                              :hide-details="hided"
                              :dense="dense"
                              :label="'200h ' + $t('Numărul părţii')"
                              @focus="$set(dictionary, '200hvshow' + ind, true)"
                              @blur="$set(dictionary, '200hvshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['200hvshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['200hvshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.200.0.h', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.200.0.h', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                            </v-text-field>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-row dense>
                        <v-col v-for="(sub, ind) in record.fields['200'][0].i" :key="'200i' + ind" cols="12">
                          <span class="d-none">{{ render }}</span>
                          <v-hover v-slot="{ hover }">
                            <v-text-field
                              v-model="sub.val"
                              :rules="validateValue('200', 'i')"
                              :hide-details="hided"
                              :dense="dense"
                              :label="'200i ' + $t('Numele părţii')"
                              @focus="$set(dictionary, '200ivshow' + ind, true)"
                              @blur="$set(dictionary, '200ivshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['200ivshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['200ivshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.200.0.i', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.200.0.i', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                            </v-text-field>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['215'][0].a[0].val"
                        :rules="validateValue('215', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'215a ' + $t('Desemnarea specifică')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['215'][0].c[0].val"
                        :rules="validateValue('215', 'c')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'215c ' + $t('Alte detalii fizice')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['215'][0].d[0].val"
                        :rules="validateValue('215', 'd')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'215d ' + $t('Dimensiuni')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['215'][0].e[0].val"
                        :rules="validateValue('215', 'e')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'215e ' + $t('Material însoţitor')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="record.fields['101'][0].a[0].val"
                        :rules="validateValue('101', 'a')"
                        style="width:300px"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'101a ' + $t('Limba')"
                        :items="dictionary['ISO-639']"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="record.fields['102'][0].a[0].val"
                        :rules="validateValue('102', 'a')"
                        style="width:300px"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'102a ' + $t('Ţara de publicare')"
                        :items="dictionary['ISO-3166']"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-for="(fld, ind) in record.fields['225']" :key="'225' + ind" dense>
                    <v-col cols="12">
                      <span class="d-none">{{ render }}</span>
                      <v-hover v-slot="{ hover }">
                        <s-autocomplete
                          v-model="fld.a[0].val"
                          :rules="validateValue('225', 'a')"
                          :label="'225a ' + $t('Titlul seriei')"
                          :dense="dense"
                          :hide-details="hided"
                          :clearable="false"
                          view-type="marc"
                          :item-text="'fields.225.a.val'"
                          :item-value="'fields.225.a.val'"
                          collection="ebib_marc_mon"
                          @focus="$set(dictionary, '225avshow' + ind, true)"
                          @blur="$set(dictionary, '225avshow' + ind, false)"
                        >
                          <template #append-outer>
                            <div
                              v-show="dictionary['225avshow' + ind] || hover"
                              :class="{ 'd-inline-flex': dictionary['225avshow' + ind] || hover }"
                            >
                              <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.225', ind + 1)">
                                mdi-plus
                              </v-icon>
                              <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.225', ind)">
                                mdi-minus
                              </v-icon>
                            </div>
                          </template>
                        </s-autocomplete>
                      </v-hover>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="fld['v'][0].val"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'225v ' + $t('Desemnarea volumului')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="fld['h'][0].val"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'225h ' + $t('Numărul părții')"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col class="pr-sm-3" cols="12" sm="6">
                      <v-row v-for="(fld, ind) in record.fields['686']" :key="'686' + ind" dense>
                        <v-hover v-slot="{ hover }">
                          <v-col cols="12">
                            <span class="d-none">{{ render }}</span>
                            <v-select
                              v-model="fld.a[0].val"
                              :rules="validateValue('686', 'a')"
                              :hide-details="hided"
                              :dense="dense"
                              item-text="name"
                              item-value="value"
                              :label="'686a ' + $t('Alte clasificări')"
                              :items="dictionary.divizionara"
                              @focus="$set(dictionary, '686avshow' + ind, true)"
                              @blur="$set(dictionary, '686avshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['686avshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['686avshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.686', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.686', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                            </v-select>
                          </v-col>
                        </v-hover>
                      </v-row>
                      <v-row v-for="(fld, ind) in record.fields['608']" :key="'608' + ind" dense>
                        <v-hover v-slot="{ hover }">
                          <v-col cols="12">
                            <span class="d-none">{{ render }}</span>
                            <s-autocomplete
                              :value="fld.a[0].val"
                              where='"ctg":"tezaur",'
                              category="tezaur"
                              record-field="280"
                              :label="'608a ' + $t('Caracteristicile rubricii')"
                              :dense="dense"
                              :hide-details="hided"
                              :clearable="false"
                              return-object
                              show-add
                              view-type="marcath"
                              item-text="fields.299.a.val"
                              item-value="fields.299.a.val"
                              collection="ebib_marc_ath"
                              @search="searchRelationMarc('608', 'a', ind, 'ath', 'tezaur')"
                              @input="setLink('608', 'a', ind, 'ath', 'tezaur', $event)"
                              @focus="$set(dictionary, '608avshow' + ind, true)"
                              @blur="$set(dictionary, '608avshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['608avshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['608avshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.608', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.608', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                              <template #append>
                                <v-icon
                                  v-show="dictionary['608avshow' + ind] || hover"
                                  class="pt-1"
                                  tabindex="-1"
                                  @click.stop.prevent="searchRelationMarc('608', 'a', ind, 'ath', 'tezaur')"
                                >
                                  mdi-magnify
                                </v-icon>
                              </template>
                            </s-autocomplete>
                          </v-col>
                        </v-hover>
                      </v-row>
                      <v-row v-for="(fld, ind) in record.fields['615']" :key="'615' + ind" dense>
                        <v-hover v-slot="{ hover }">
                          <v-col cols="12">
                            <span class="d-none">{{ render }}</span>
                            <s-autocomplete
                              v-model="fld.a[0].val"
                              :rules="validateValue('615', 'a')"
                              :label="'615a ' + $t('Categoria de subiect')"
                              :dense="dense"
                              :hide-details="hided"
                              :clearable="false"
                              view-type="marc"
                              :item-text="'fields.615.a.val'"
                              :item-value="'fields.615.a.val'"
                              collection="ebib_marc_mon"
                              @focus="$set(dictionary, '615avshow' + ind, true)"
                              @blur="$set(dictionary, '615avshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['615avshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['615avshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.615', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.615', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                            </s-autocomplete>
                          </v-col>
                        </v-hover>
                      </v-row>
                    </v-col>
                    <v-col class="pl-sm-3" cols="12" sm="6">
                      <v-row v-for="(fld, ind) in record.fields['606']" :key="'606' + ind" dense>
                        <v-hover v-slot="{ hover }">
                          <v-col cols="12">
                            <span class="d-none">{{ render }}</span>
                            <s-autocomplete
                              :value="fld.a[0].val"
                              where='"ctg":"subiect",'
                              category="subiect"
                              record-field="250"
                              :label="'606a ' + $t('Vedetă de subiect')"
                              :dense="dense"
                              :hide-details="hided"
                              :clearable="false"
                              return-object
                              show-add
                              view-type="marcath"
                              item-text="fields.299.a.val"
                              item-value="fields.299.a.val"
                              collection="ebib_marc_ath"
                              @search="searchRelationMarc('606', 'a', ind, 'ath', 'subiect')"
                              @input="setLink('606', 'a', ind, 'ath', 'tezaur', $event)"
                              @focus="$set(dictionary, '606avshow' + ind, true)"
                              @blur="$set(dictionary, '606avshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['606avshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['606avshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.606', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.606', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                              <template #append>
                                <v-icon
                                  v-show="dictionary['606avshow' + ind] || hover"
                                  class="pt-1"
                                  tabindex="-1"
                                  @click.stop.prevent="searchRelationMarc('606', 'a', ind, 'ath', 'subiect')"
                                >
                                  mdi-magnify
                                </v-icon>
                              </template>
                            </s-autocomplete>
                          </v-col>
                        </v-hover>
                      </v-row>
                      <v-row v-for="(fld, ind) in record.fields['610'][0].a" :key="'610' + ind" dense>
                        <v-hover v-slot="{ hover }">
                          <v-col cols="12">
                            <span class="d-none">{{ render }}</span>
                            <s-autocomplete
                              v-model="fld.val"
                              :rules="validateValue('610', 'a')"
                              :label="'610a ' + $t('Termen de subiect')"
                              :dense="dense"
                              :hide-details="hided"
                              :clearable="false"
                              view-type="marc"
                              :item-text="'fields.610.a.val'"
                              :item-value="'fields.610.a.val'"
                              collection="ebib_marc_mon"
                              @focus="$set(dictionary, '610avshow' + ind, true)"
                              @blur="$set(dictionary, '610avshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['610avshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['610avshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.610.0.a', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.610.0.a', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                            </s-autocomplete>
                          </v-col>
                        </v-hover>
                      </v-row>
                      <v-row v-for="(fld, ind) in record.fields['675']" :key="'675' + ind" dense>
                        <v-hover v-slot="{ hover }">
                          <v-col cols="12">
                            <span class="d-none">{{ render }}</span>
                            <s-autocomplete
                              v-model="fld.a[0].val"
                              :rules="validateValue('675', 'a')"
                              :label="'675a ' + $t('Număr (indice) CZU')"
                              :dense="dense"
                              :hide-details="hided"
                              :clearable="false"
                              view-type="marc"
                              :item-text="'fields.675.a.val'"
                              :item-value="'fields.675.a.val'"
                              collection="ebib_marc_mon"
                              @focus="$set(dictionary, '675avshow' + ind, true)"
                              @blur="$set(dictionary, '675avshow' + ind, false)"
                            >
                              <template #append-outer>
                                <div
                                  v-show="dictionary['675avshow' + ind] || hover"
                                  :class="{ 'd-inline-flex': dictionary['675avshow' + ind] || hover }"
                                >
                                  <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.675', ind + 1)">
                                    mdi-plus
                                  </v-icon>
                                  <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.675', ind)">
                                    mdi-minus
                                  </v-icon>
                                </div>
                              </template>
                            </s-autocomplete>
                          </v-col>
                        </v-hover>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col v-for="(fld, ind) in record.fields['300']" :key="'300' + ind" cols="12">
                      <v-hover v-slot="{ hover }">
                        <v-textarea
                          v-model="fld.a[0].val"
                          :rules="validateValue('300', 'a')"
                          rows="3"
                          :hide-details="hided"
                          :dense="dense"
                          :label="'300a ' + $t('Notă generala')"
                          @focus="$set(dictionary, '300avshow' + ind, true)"
                          @blur="$set(dictionary, '300avshow' + ind, false)"
                        >
                          <template #append-outer>
                            <div
                              v-show="dictionary['300avshow' + ind] || hover"
                              :class="{ 'd-inline-flex': dictionary['300avshow' + ind] || hover }"
                            >
                              <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.300', ind + 1)">
                                mdi-plus
                              </v-icon>
                              <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.300', ind)">
                                mdi-minus
                              </v-icon>
                            </div>
                          </template>
                        </v-textarea>
                      </v-hover>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="record.fields['327'][0].a[0].val"
                        rows="3"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'327a ' + $t('Notă de conținut')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-subheader>{{ "330a " + $t("Notă rezumat/abstract") }}</v-subheader>
                      <quill-editor v-model="record.fields['330'][0].a[0].val" class="editor" :options="editorOption" />
                      <!-- <v-textarea
                        v-model="record.fields['330'][0].a[0].val"
                        :rules="validateValue('330', 'a')"
                        rows="3"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'330a ' + $t('Notă rezumat/abstract')"
                      /> -->
                    </v-col>
                    <v-col v-for="(fld, ind) in record.fields['345'][0].a" :key="'345' + ind" cols="12">
                      <v-hover v-slot="{ hover }">
                        <v-textarea
                          v-model="fld.val"
                          :rules="validateValue('345', 'a')"
                          rows="3"
                          :hide-details="hided"
                          :dense="dense"
                          :label="'345a ' + $t('Sursa de achiziţie / Adr. furnizorului')"
                          @focus="$set(dictionary, '345avshow' + ind, true)"
                          @blur="$set(dictionary, '345avshow' + ind, false)"
                        >
                          <template #append-outer>
                            <div
                              v-show="dictionary['345avshow' + ind] || hover"
                              :class="{ 'd-inline-flex': dictionary['345avshow' + ind] || hover }"
                            >
                              <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.345.0.a', ind + 1)">
                                mdi-plus
                              </v-icon>
                              <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.345.0.a', ind)">
                                mdi-minus
                              </v-icon>
                            </div>
                          </template>
                        </v-textarea>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-subheader>{{ "461 " + $t("Nivel set") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['461']"
                    :render="render"
                    collection="ebib_marc_mon"
                    @add="addSubItem('fields.461', $event)"
                    @del="delSubItem('fields.461', $event, 'mon')"
                    @search="searchRelationMarc('461', 't', $event, 'mon', null)"
                    @linking="setLinking('461', $event)"
                  />
                  <v-subheader>{{ "462 " + $t("Nivel subset") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['462']"
                    :render="render"
                    collection="ebib_marc_mon"
                    @add="addSubItem('fields.462', $event)"
                    @del="delSubItem('fields.462', $event, 'mon')"
                    @search="searchRelationMarc('462', 't', $event, 'mon', null)"
                    @linking="setLinking('462', $event)"
                  />
                  <v-subheader>{{ "463 " + $t("Nivel unitate") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['463']"
                    :render="render"
                    collection="ebib_marc_mon"
                    @add="addSubItem('fields.463', $event)"
                    @del="delSubItem('fields.463', $event, 'mon')"
                    @search="searchRelationMarc('463', 't', $event, 'mon', null)"
                    @linking="setLinking('463', $event)"
                  />
                  <v-subheader>{{ "421 " + $t("Supliment") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['421']"
                    :render="render"
                    collection="ebib_marc_mon"
                    @add="addSubItem('fields.421', $event)"
                    @del="delSubItem('fields.421', $event, 'mon')"
                    @search="searchRelationMarc('421', 't', $event, 'mon', null)"
                    @linking="setLinking('421', $event)"
                  />
                  <v-subheader>{{ "422 " + $t("Sursă supliment") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['422']"
                    :render="render"
                    collection="ebib_marc_mon"
                    @add="addSubItem('fields.422', $event)"
                    @del="delSubItem('fields.422', $event, 'mon')"
                    @search="searchRelationMarc('422', 't', $event, 'mon', null)"
                    @linking="setLinking('422', $event)"
                  />
                  <v-subheader>{{ "423 " + $t("Publicat împreuna cu") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['423']"
                    :render="render"
                    collection="ebib_marc_mon"
                    @add="addSubItem('fields.423', $event)"
                    @del="delSubItem('fields.423', $event, 'mon')"
                    @search="searchRelationMarc('423', 't', $event, 'mon', null)"
                    @linking="setLinking('423', $event)"
                  />
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <span class="d-none">{{ render }}</span>
                  <manage-resource
                    ref="manageResource"
                    class="mb-4 mx-2"
                    :record="record"
                    :height="120"
                    :width="100"
                    :render="render"
                    @add="addResource"
                    @del="delResource"
                    @chg="chgResource"
                  />
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        v-model="record.fields['001'][0]['_'][0].val"
                        :hide-details="hided"
                        :dense="dense"
                        readonly
                        :label="'001_' + $t('Identificator înregistrare')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="record.fields['005'][0]['_'][0].val"
                        :hide-details="hided"
                        :dense="dense"
                        readonly
                        :label="'005_ ' + $t('Identificator versiune')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="record.fields['006'][0].a[0].val"
                        :hide-details="hided"
                        :dense="dense"
                        readonly
                        :label="'006_ ' + $t('Link acces activ')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <record-history :id="record._id" prefix="mon" @sheet="$emit('sheet', $event)" />
                </v-tab-item>
              </base-material-tabs>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <search-link ref="searchLink" select-event @select="setLinkValue" />
    <create-edit ref="createEdit" />
  </v-container>
</template>

<script>
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"
import { quillEditor } from "vue-quill-editor"
import SearchLink from "../../../components/containers/SearchLink"
import RecordHistory from "../../../components/containers/RecordHistory"
import CreateEdit from "../../../views/settings/AddDictionaryEdit"
import SAutocomplete from "../../../components/inputs/Autocomplete"
// import SDateTime from "../../../components/inputs/DateTime"
import AddImageMultiple from "../../../components/containers/AddImageMultiple"
import LinkFields from "../../../components/containers/LinkFields"
import ManageResource from "../../../components/containers/ManageResource"
// import IsbdInfo from "../../../components/containers/IsbdInfo"
import { EventBus } from "../../../EventBus"
import axios from "../../../plugins/axios"
//import axio from "axios"
export default {
  name: "DashboardDashboard",
  components: {
    SearchLink,
    CreateEdit,
    SAutocomplete,
    ManageResource,
    RecordHistory,
    // SDateTime,
    AddImageMultiple,
    LinkFields,
    quillEditor
  },
  data() {
    return {
      dense: true,
      hided: false,
      fileName: "",
      file: {},
      urlImg: null,
      searchId: "",
      dictionary: {},
      linkArray: null,
      linkField: null,
      linkSubfield: null,
      linkIndex: null,
      selectId: null,
      editorOption: {
        modules: {
          // toolbar: [
          //   ["bold", "italic", "underline", "strike"],
          //   ["blockquote", "code-block"],
          //   [{ list: "ordered" }, { list: "bullet" }],
          //   [{ script: "sub" }, { script: "super" }],
          //   [{ indent: "-1" }, { indent: "+1" }],
          //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
          //   [{ color: [] }, { background: [] }],
          //   [{ align: [] }],
          //   ["clean"]
          // ]
          // syntax: true,
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"]
          ],
          history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true
          }
        },
        placeholder: ""
      },
      render: 1,
      record: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          100: [{ ord: "", a: [{ ord: "", val: "" }] }],
          101: [
            {
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }]
            }
          ],
          102: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }]
            }
          ],
          105: [{ ord: "", a: [{ ord: "", val: "" }] }],
          106: [{ ord: "", a: [{ ord: "", val: "" }] }],
          110: [{ ord: "", a: [{ ord: "", val: "" }] }],
          115: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          116: [{ ord: "", a: [{ ord: "", val: "" }] }],
          117: [{ ord: "", a: [{ ord: "", val: "" }] }],
          120: [{ ord: "", a: [{ ord: "", val: "" }] }],
          127: [{ ord: "", a: [{ ord: "", val: "" }] }],
          200: [
            {
              5: [{ ord: "", val: "" }],
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          205: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }]
            }
          ],
          206: [{ ord: "", a: [{ ord: "", val: "" }] }],
          207: [{ ord: "", ind2: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          210: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              link: "",
              c: [{ ord: "", src_id: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }]
            }
          ],
          211: [{ ord: "", a: [{ ord: "", val: "" }] }],
          215: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          225: [
            {
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          230: [{ ord: "", a: [{ ord: "", val: "" }] }],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          303: [{ ord: "", a: [{ ord: "", val: "" }] }],
          307: [{ ord: "", a: [{ ord: "", val: "" }] }],
          310: [{ ord: "", a: [{ ord: "", val: "" }] }],
          317: [
            {
              5: [{ ord: "", val: "" }],
              6: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              u: [{ ord: "", val: "" }]
            }
          ],
          320: [{ ord: "", a: [{ ord: "", val: "" }] }],
          326: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          327: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          330: [{ ord: "", a: [{ ord: "", val: "" }] }],
          345: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          410: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          411: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          412: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          413: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          421: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          422: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          423: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          424: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          425: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          430: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          431: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          432: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          433: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          434: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          435: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          436: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          437: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          440: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          441: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          442: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          443: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          444: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          445: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          446: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          447: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          448: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          451: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          452: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          453: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          454: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          455: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          456: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          461: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          462: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          463: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          464: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          470: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          481: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          482: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          488: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          500: [
            { ord: "", ind2: "", ind1: "", link: "", a: [{ ord: "", src_id: "", val: "" }], b: [{ ord: "", val: "" }] }
          ],
          501: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          503: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          510: [{ ord: "", ind2: "", a: [{ ord: "", val: "" }] }],
          511: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          512: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          513: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          514: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          515: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          516: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          517: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          518: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          520: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          530: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          531: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          532: [{ ord: "", ind2: "", ind1: "", a: [{ ord: "", val: "" }] }],
          540: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          541: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          545: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          560: [{ ord: "", ind2: "", a: [{ ord: "", val: "" }] }],
          600: [
            {
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }]
            }
          ],
          601: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }]
            }
          ],
          602: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }], f: [{ ord: "", val: "" }] }],
          604: [{ ord: "", a: [{ ord: "", val: "" }] }],
          605: [
            {
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }]
            }
          ],
          606: [{ ord: "", ind1: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          607: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          608: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          610: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          615: [{ ord: "", a: [{ ord: "", val: "" }], n: [{ ord: "", val: "" }] }],
          616: [{ ord: "", a: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          617: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          620: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          621: [
            {
              5: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          626: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          660: [{ ord: "", a: [{ ord: "", val: "" }] }],
          661: [{ ord: "", a: [{ ord: "", val: "" }] }],
          670: [
            {
              ord: "",
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          675: [
            {
              3: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          676: [
            {
              3: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          680: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          686: [
            {
              2: [{ ord: "", val: "" }],
              3: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }]
            }
          ],
          700: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          701: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          702: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          710: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          711: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          712: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          716: [
            {
              3: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              c: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          720: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          721: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          722: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          730: [{ 4: [{ ord: "", val: "" }], ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          801: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }]
            }
          ],
          802: [{ ord: "", a: [{ ord: "", val: "" }] }],
          830: [{ ord: "", a: [{ ord: "", val: "" }] }],
          850: [{ ord: "", a: [{ ord: "", val: "" }] }],
          852: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              k: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }],
              t: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }]
            }
          ],
          855: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          856: [{ ord: "", link: "", f: [{ ord: "", src_id: "", val: "" }] }],
          886: [{ 2: [{ ord: "", val: "" }], ord: "", ind1: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          901: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }]
            }
          ],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          920: [{ ord: "", a: [{ ord: "", val: "" }] }],
          921: [{ ord: "", a: [{ ord: "", val: "" }] }],
          922: [{ ord: "", a: [{ ord: "", val: "" }] }],
          940: [{ ord: "", a: [{ ord: "", val: "" }] }],
          941: [{ ord: "", a: [{ ord: "", val: "" }] }],
          943: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          945: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          947: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          948: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          949: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          950: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              p: [{ ord: "", val: false }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              w: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: false }]
            }
          ],
          953: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              p: [{ ord: "", val: false }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              w: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: false }]
            }
          ],
          955: [{ ord: "", a: [{ ord: "", val: "" }] }],
          957: [{ ord: "", a: [{ ord: "", val: "" }], link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          958: [{ ord: "", a: [{ ord: "", val: "" }], link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          959: [{ ord: "", a: [{ ord: "", val: "" }], link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          975: [{ ord: "", a: [{ ord: "", val: "" }] }],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          990: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "010": [
            {
              6: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          "011": [
            {
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          "012": [{ 2: [{ ord: "", val: "" }], 5: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          "013": [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          "015": [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], d: [{ ord: "", val: "" }] }],
          "016": [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          "020": [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          "021": [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          "035": [{ ord: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          "040": [{ ord: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      recordView: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          100: [{ ord: "", a: [{ ord: "", val: "" }] }],
          101: [
            {
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }]
            }
          ],
          102: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }]
            }
          ],
          105: [{ ord: "", a: [{ ord: "", val: "" }] }],
          106: [{ ord: "", a: [{ ord: "", val: "" }] }],
          110: [{ ord: "", a: [{ ord: "", val: "" }] }],
          115: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          116: [{ ord: "", a: [{ ord: "", val: "" }] }],
          117: [{ ord: "", a: [{ ord: "", val: "" }] }],
          120: [{ ord: "", a: [{ ord: "", val: "" }] }],
          127: [{ ord: "", a: [{ ord: "", val: "" }] }],
          200: [
            {
              5: [{ ord: "", val: "" }],
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          205: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }]
            }
          ],
          206: [{ ord: "", a: [{ ord: "", val: "" }] }],
          207: [{ ord: "", ind2: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          210: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              link: "",
              c: [{ ord: "", src_id: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }]
            }
          ],
          211: [{ ord: "", a: [{ ord: "", val: "" }] }],
          215: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          225: [
            {
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          230: [{ ord: "", a: [{ ord: "", val: "" }] }],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          303: [{ ord: "", a: [{ ord: "", val: "" }] }],
          307: [{ ord: "", a: [{ ord: "", val: "" }] }],
          310: [{ ord: "", a: [{ ord: "", val: "" }] }],
          317: [
            {
              5: [{ ord: "", val: "" }],
              6: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              u: [{ ord: "", val: "" }]
            }
          ],
          320: [{ ord: "", a: [{ ord: "", val: "" }] }],
          326: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          327: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          330: [{ ord: "", a: [{ ord: "", val: "" }] }],
          345: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          410: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          411: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          412: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          413: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          421: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          422: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          423: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          424: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          425: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          430: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          431: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          432: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          433: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          434: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          435: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          436: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          437: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          440: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          441: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          442: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          443: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          444: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          445: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          446: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          447: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          448: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          451: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          452: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          453: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          454: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          455: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          456: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          461: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          462: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          463: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          464: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          470: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          481: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          482: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          488: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          500: [
            { ord: "", ind2: "", ind1: "", link: "", a: [{ ord: "", src_id: "", val: "" }], b: [{ ord: "", val: "" }] }
          ],
          501: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          503: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          510: [{ ord: "", ind2: "", a: [{ ord: "", val: "" }] }],
          511: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          512: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          513: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          514: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          515: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          516: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          517: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          518: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          520: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          530: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          531: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          532: [{ ord: "", ind2: "", ind1: "", a: [{ ord: "", val: "" }] }],
          540: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          541: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          545: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          560: [{ ord: "", ind2: "", a: [{ ord: "", val: "" }] }],
          600: [
            {
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }]
            }
          ],
          601: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }]
            }
          ],
          602: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }], f: [{ ord: "", val: "" }] }],
          604: [{ ord: "", a: [{ ord: "", val: "" }] }],
          605: [
            {
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }]
            }
          ],
          606: [{ ord: "", ind1: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          607: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          608: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          610: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          615: [{ ord: "", a: [{ ord: "", val: "" }], n: [{ ord: "", val: "" }] }],
          616: [{ ord: "", a: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          617: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          620: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          621: [
            {
              5: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          626: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          660: [{ ord: "", a: [{ ord: "", val: "" }] }],
          661: [{ ord: "", a: [{ ord: "", val: "" }] }],
          670: [
            {
              ord: "",
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          675: [
            {
              3: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          676: [
            {
              3: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          680: [{ 3: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          686: [
            {
              2: [{ ord: "", val: "" }],
              3: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }]
            }
          ],
          700: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          701: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          702: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          710: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          711: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          712: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          716: [
            {
              3: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              c: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          720: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          721: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          722: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          730: [{ 4: [{ ord: "", val: "" }], ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          801: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }]
            }
          ],
          802: [{ ord: "", a: [{ ord: "", val: "" }] }],
          830: [{ ord: "", a: [{ ord: "", val: "" }] }],
          850: [{ ord: "", a: [{ ord: "", val: "" }] }],
          852: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              k: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }],
              t: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }]
            }
          ],
          855: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          856: [{ ord: "", link: "", f: [{ ord: "", src_id: "", val: "" }] }],
          886: [{ 2: [{ ord: "", val: "" }], ord: "", ind1: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          901: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }]
            }
          ],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          920: [{ ord: "", a: [{ ord: "", val: "" }] }],
          921: [{ ord: "", a: [{ ord: "", val: "" }] }],
          922: [{ ord: "", a: [{ ord: "", val: "" }] }],
          940: [{ ord: "", a: [{ ord: "", val: "" }] }],
          941: [{ ord: "", a: [{ ord: "", val: "" }] }],
          943: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          945: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          947: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          948: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          949: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          950: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              p: [{ ord: "", val: false }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              w: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: false }]
            }
          ],
          953: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              p: [{ ord: "", val: false }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              w: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: false }]
            }
          ],
          955: [{ ord: "", a: [{ ord: "", val: "" }] }],
          957: [{ ord: "", a: [{ ord: "", val: "" }], link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          958: [{ ord: "", a: [{ ord: "", val: "" }], link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          959: [{ ord: "", a: [{ ord: "", val: "" }], link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          975: [{ ord: "", a: [{ ord: "", val: "" }] }],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          990: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "010": [
            {
              6: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          "011": [
            {
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          "012": [{ 2: [{ ord: "", val: "" }], 5: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          "013": [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          "015": [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], d: [{ ord: "", val: "" }] }],
          "016": [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          "020": [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          "021": [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          "035": [{ ord: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          "040": [{ ord: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      schema: {
        dictionary: ["all.ISO-639", "all.ISO-3166", "all.divizionara"]
      },
      rows: []
    }
  },

  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  created() {
    //this.$log("appSchema ", this.appSchema)
    if (this.schema.dictionary) {
      this.schema.dictionary.map(itm => this.getDictionaryValues(itm))
    }
    const country = []
    this.rows.map(itm => {
      country.push({ name: itm.value, value: itm.value })
    })
    EventBus.$on("dictionary-reload", dict => {
      this.$log("dictionary-reload ", dict)
      if (this.selectId == dict.id) {
        this.getDictionaryValues("all." + dict.dictionary)
        if (dict.select) {
          this.setLinkValue(dict.select)
        }
      }
    })
    // EventBus.$on("table-form-select", value => {
    //   this.$log("selected ", value)
    // })
    //this.$log(JSON.stringify(country))
    // axios.post("anaf_check_tva", [{ cui: 18158683, data: "2020-04-30" }]).then(response => {
    //   this.$log("response ", response)
    // })
  },
  methods: {
    setLinking(field, id) {
      this.$log(field, id)
    },
    addResource(res) {
      this.$log("addResource ", res)
      if (this.record.fields[res.field][0].link) {
        this.record.fields[res.field].push({ link: res.link, ord: res.ord, a: [{ val: res.name }] })
      } else {
        this.$set(this.record.fields[res.field][0], "link", res.link)
        this.$set(this.record.fields[res.field][0], "ord", res.ord)
        this.$set(this.record.fields[res.field][0].a[0], "val", res.name)
      }
      this.render++
    },
    delResource(res) {
      this.$log("delResource ", res)
      const i = this.record.fields[res.field].findIndex(itm => itm.link == res.link)
      if (i >= 0) {
        if (this.record.fields[res.field].length > 1) {
          this.record.fields[res.field].splice(i, 1)
        } else {
          this.record.fields[res.field].splice(0, 1, { link: "", a: [{ val: "" }] })
        }
      }
      //this.record.fields[res.field].findInde
    },
    chgResource(chg) {
      //this.$log("chgResource ", chg)
      chg.map(item => {
        if (item.link) {
          this.$set(
            this.record.fields[item.field].find(itm => itm.link == item.link),
            "ord",
            item.ord
          )
        }
      })
    },
    validateValue(fld, sub) {
      const rule = []
      //rule.push(v => !!v || this.$t("required"))
      return rule
    },
    checkDuplicate(pre, fld, sub, val) {
      if (val) {
        axios.post("marc/is_duplicated_fs_value/" + pre + "/" + fld + "/" + sub + "/" + val).then(response => {
          this.$set(this.dictionary, fld + sub, response.data == 1)
        })
      }
    },
    validateDuplicate(mnd, dup, v) {
      const rule = []
      if (mnd) {
        rule.push(v => !!v || this.$t("required"))
      }
      if (dup) {
        rule.push(v => this.$t("Valoarea trebuie să fie unică!!!"))
      }
      return rule
    },
    createURL(img, fil) {
      this.$set(this.dictionary, img, URL.createObjectURL(fil.target.files[0]))
    },
    fileChanged(e) {
      this.file = e.target.files[0]
      this.urlImg = URL.createObjectURL(this.file)
      this.fileName = this.file.name
    },
    clearFile() {
      this.fileName = ""
      this.file = null
    },
    createAndEdit(e, arr, field, ind) {
      //searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.selectId = Date.now()
      this.$log("createAndEdit ", e)
      this.$refs.createEdit.showData(e, this.selectId)
    },
    checkTVA(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
          }
        })
      }
    },
    loadCUIData(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
            this.record.name = response.data.found[0].denumire
            const adr = response.data.found[0].adresa.split(",")
            if (adr.length > 2) {
              this.record.address.address = response.data.found[0].adresa.replace(adr[0] + "," + adr[1] + ",", "")
            }
            this.record.address.locality = adr[1]
            this.record.address.country = "România"
            if (adr[0].substr(0, 3) == "JUD") {
              const jud = adr[0].substr(5).toLowerCase()
              this.record.address.county = adr[0].substr(5)
              this.dictionary.county.map(itm => {
                if (itm.value.toLowerCase() == jud) {
                  this.record.address.county = itm.value
                }
              })
            } else {
              this.record.address.county = "Bucureşti"
            }
          }
        })
      }
    },
    getdictionary(dic) {
      return this.$store.getters.dictionary(dic)
    },
    getDictionaryValues(dic) {
      //this.$log("getDictionaryValues(itm) ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        this.$set(this.dictionary, dictionary[1], this.getdictionary("all_" + dictionary[1]))
        // this.dictionary[dictionary[1]] = response.data._items[0].content
        //})
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      }
    },
    addSubItem(e, ind) {
      if (ind == null) {
        this.resolvePath(e, this.record).push(JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0])))
      } else {
        this.resolvePath(e, this.record).splice(
          ind,
          0,
          JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0]))
        )
      }
    },
    delSubItem(e, ind) {
      this.resolvePath(e, this.record).splice(ind, 1)
      if (this.resolvePath(e, this.record).length == 0) {
        this.addSubItem(e)
      }
    },
    searchRelationMarc(field, subfield, ind, prefix, ctg) {
      this.linkField = field
      this.linkSubfield = subfield
      this.linkIndex = ind
      //this.$log("srcrel ", this.schemaContent[fields.field + subfield])
      this.searchId = Date.now()
      let where = ""
      let vTable = ""
      if (ctg) {
        where = '"ctg":' + '"' + ctg + '"'
        const ctgview = this.$store.getters.appViewTableCollectionCategory("marc_" + prefix, ctg)
        if (ctgview.length > 0) {
          vTable = ctgview[0]._id
        }
      }
      this.$refs.searchLink.open("ebib_marc_" + prefix, null, this.searchId, {
        where: where,
        viewTable: vTable,
        ctg: ctg
      })
      // EventBus.$emit("search-link", {
      //   resource: "ebib_marc_" + prefix,
      //   field: null,
      //   id: this.searchId,
      //   config: { where: where, viewTable: vTable, ctg: ctg }
      // })
      //this.$refs.searchLink.open(res, fld, this.searchId)
    },
    searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.searchId = Date.now()
      this.$refs.searchLink.open(res, fld, this.searchId)
    },
    setLink(field, subfield, ind, prefix, ctg, e) {
      //this.$log("setlink1 ", e)
      //this.$log("setlink2 ", fields)
      if (typeof e == "object") {
        this.linkField = field
        this.linkSubfield = subfield
        this.linkIndex = ind
        this.linkAction = "marc"
        axios.get("ebib_marc_" + prefix + "/" + e._id).then(response => {
          this.$log("ressetlink ", response)
          this.setLinkValue(response.data)
        })
      }
    },
    setLinkValue(e) {
      if (this.linkIndex != null) {
        if (this.linkField != null) {
          //this.linkArray[this.linkIndex][this.linkField] = e
          this.$set(this.linkArray[this.linkIndex], this.linkField, e)
        } else {
          if (typeof e === "object") {
            e.iiid = this.idGlobal++
          }
          if (this.linkIndex == -1) {
            this.linkArray.push(e)
          } else {
            //this.linkArray[this.linkIndex] = e
            this.$set(this.linkArray, this.linkIndex, e)
          }
        }
      } else {
        //this.linkArray[this.linkField] = e
        this.$set(this.linkArray, this.linkField, e)
        this.render = Date.now()
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
